body {
    min-width: 1700px;
}

@font-face {
    font-family: "Happiness Sans Title";
    src: url("./fonts/happinessSans/ttf/Happiness-Sans-Title.ttf") format("truetype"),
        url("./fonts/happinessSans/webfont/Happiness-Sans-Title.woff") format("woff"),
        url("./fonts/happinessSans/webfont/Happiness-Sans-Title.woff2") format("woff2"),
        url("./fonts/happinessSans/otf/Happiness-Sans-Title.otf") format("opentype");
}

@font-face {
    font-family: "Happiness Sans Regular";
    src: url("./fonts/happinessSans/ttf/Happiness-Sans-Regular.ttf") format("truetype"),
        url("./fonts/happinessSans/webfont/Happiness-Sans-Regular.woff") format("woff"),
        url("./fonts/happinessSans/webfont/Happiness-Sans-Regular.woff2") format("woff2"),
        url("./fonts/happinessSans/otf/Happiness-Sans-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Noto Sans KR";
    font-style: thin;
    font-weight: 100;
    src: url("./fonts/notoSans/webfont/NotoSansKR-Thin.woff") format("woff"),
        url("./fonts/notoSans/webfont/NotoSansKR-Thin.woff2") format("woff2"),
        /* Safari, Android, iOS */ url("./fonts/notoSans/otf/NotoSansKR-Thin.otf") format("opentype");
}

@font-face {
    font-family: "Noto Sans KR";
    font-style: light;
    font-weight: 300;
    src: url("./fonts/notoSans/webfont/NotoSansKR-Light.woff") format("woff"),
        url("./fonts/notoSans/webfont/NotoSansKR-Light.woff2") format("woff2"),
        /* Safari, Android, iOS */ url("./fonts/notoSans/otf/NotoSansKR-Light.otf") format("opentype");
}

@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;
    src: url("./fonts/notoSans/webfont/NotoSansKR-Regular.woff") format("woff"),
        url("./fonts/notoSans/webfont/NotoSansKR-Regular.woff2") format("woff2"),
        /* Safari, Android, iOS */ url("./fonts/notoSans/otf/NotoSansKR-Regular.otf") format("opentype");
}

@font-face {
    font-family: "Noto Sans KR";
    font-style: medium;
    font-weight: 500;
    src: url("./fonts/notoSans/webfont/NotoSansKR-Medium.woff") format("woff"),
        url("./fonts/notoSans/webfont/NotoSansKR-Medium.woff2") format("woff2"),
        /* Safari, Android, iOS */ url("./fonts/notoSans/otf/NotoSansKR-Medium.otf") format("opentype");
}

@font-face {
    font-family: "Noto Sans KR";
    font-style: bold;
    font-weight: 700;
    src: url("./fonts/notoSans/webfont/NotoSansKR-Bold.woff") format("woff"),
        url("./fonts/notoSans/webfont/NotoSansKR-Bold.woff2") format("woff2"),
        /* Safari, Android, iOS */ url("./fonts/notoSans/otf/NotoSansKR-Bold.otf") format("opentype");
}

@font-face {
    font-family: "Noto Sans KR";
    font-style: Black;
    font-weight: 900;
    src: url("./fonts/notoSans/webfont/NotoSansKR-Black.woff") format("woff"),
        url("./fonts/notoSans/webfont/NotoSansKR-Black.woff2") format("woff2"),
        /* Safari, Android, iOS */ url("./fonts/notoSans/otf/NotoSansKR-Black.otf") format("opentype");
}
